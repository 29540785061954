<template>
  <b-sidebar
    id="add-new-machine-sidebar"
    :visible="isAddNewMachineSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-machine-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('machines.add_new_machine') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              :label="$t('machines.machine_name')"
              label-for="name"
            >
              <b-form-input
                id="full-name"
                v-model="machineData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Machinename -->
          <validation-provider
            #default="validationContext"
            name="MAC"
            rules="required"
          >
            <b-form-group
              :label="$t('machines.mac')"
              label-for="mac"
            >
              <b-form-input
                id="mac"
                v-model="machineData.mac"
                :state=" resOk.mac.success ? false : getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}{{ resOk.mac.error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Version -->
          <validation-provider
            #default="validationContext"
            name="Version"
          >
            <b-form-group
              :label="$t('machines.sw_version')"
              label-for="version"
            >
              <b-form-input
                id="version"
                v-model="machineData.version"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Serial"
            rules="alpha-num"
          >
            <b-form-group
              :label="$t('machines.serial')"
              label-for="serial"
            >
              <b-form-input
                id="serial"
                v-model="machineData.serial"
                type="password"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- type -->
          <validation-provider
            #default="validationContext"
            name="Type"
            rule="required"
          >
            <b-form-group
              :label="$t('machines.type')"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="machineData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="Company"
          >
            <b-form-group
              :label="$t('machines.company')"
              label-for="company"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="machineData.company_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companyOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="company"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Machine Role -->
          <validation-provider
            #default="validationContext"
            name="Object"
          >
            <b-form-group
              :label="$t('machines.location')"
              label-for="object"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="machineData.object_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="objectOptions.filter(item => item.company === machineData.company_id)"
                :reduce="val => val.value"
                :clearable="true"
                input-id="object"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('common.add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('common.cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewMachineSidebarActive',
    event: 'update:is-add-new-machine-sidebar-active',
  },
  props: {
    isAddNewMachineSidebarActive: {
      type: Boolean,
      required: true,
    },
    objectOptions: {
      type: Array,
      required: true,
    },
    companyOptions: {
      type: Array,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankMachineData = {
      name: '',
      mac: '',
      serial: '',
      type: '',
      version: '',
      company_id: null,
      object_id: null,
      status: 'inactive',
    }

    const machineData = ref(JSON.parse(JSON.stringify(blankMachineData)))
    const resetmachineData = () => {
      machineData.value = JSON.parse(JSON.stringify(blankMachineData))
    }

    const ok = {
      mac: {
        success: null,
        error: '',
      },
    }

    const resOk = ref({})
    resOk.value = ok

    const onSubmit = () => {
      resOk.value = ok
      store.dispatch('app-machine/addMachine', machineData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-machine-sidebar-active', false)
        })
        .catch(e => {
          // console.log(e)
          if (typeof e === 'object') {
            if (e.status === 400 && e.data && e.data.error) {
              Object.keys(e.data.error).forEach(err => {
                if (e.data.error[err]) {
                  resOk.value[err].success = true
                  resOk.value[err].error = e.data.error[err].join('; ')
                  // console.log(resOk)
                  // eslint-disable-next-line
                  // alert(`${err}: ${e.data.error[err].join('; ')}`)
                }
              })
            }
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetmachineData)

    return {
      machineData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resOk,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-machine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
