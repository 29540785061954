<template>

  <div>

    <machine-list-add-new
      :is-add-new-machine-sidebar-active.sync="isAddNewMachineSidebarActive"
      :object-options="objectOptions"
      :company-options="companyOptions"
      :type-options="typeOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <machines-list-filters
      :object-filter.sync="objectFilter"
      :company-filter.sync="companyFilter"
      :status-filter.sync="statusFilter"
      :object-options="objectOptions"
      :company-options="companyOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('common.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('common.entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('common.search')"
              />
              <b-button
                variant="primary"
                @click="isAddNewMachineSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('machines.add_machine') }}</span>
              </b-button>

              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="fetchMachinesFile()">
                  <feather-icon icon="AlignJustifyIcon" />
                  <span class="align-middle ml-50">{{ $t('common.save_xlsx') }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  :disabled="true"
                  @click="fetchSalesFile('pdf')"
                >
                  <feather-icon icon="BookIcon" />
                  <span class="align-middle ml-50">{{ $t('common.save_pdf') }}</span>
                </b-dropdown-item>
              </b-dropdown>

              <b-button
                variant="flat-info"
                class="btn-icon rounded-circle"
                @click="refetchData"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  size="18"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refMachineListTable"
        class="position-relative"
        :items="fetchMachines"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Machine -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link
              :to_="{ name: 'apps-machines-view', params: { id: data.item.id } }"
              :to="{ name: 'apps-machines-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.mac }}</small>
          </b-media>
        </template>

        <!-- Column: Object  -->
        <template #cell(event_time)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.event_time }}</span>
          </div>
        </template>

        <template #cell(description)="data">
          <div>
            <span
              v-if="data.item.popup && data.item.popup.length"
              class="align-text-top text-primary"
            ><a
              @click="customPopup(data.item.popup)"
            >{{ data.item.description }}</a>
            </span>
            <span
              v-else-if="data.item.popup"
              class="align-text-top text-danger"
            >{{ data.item.description }}</span>
            <span
              v-else
              class="align-text-top"
            >
              {{ data.item.description && data.item.description.desc ? data.item.description.desc[$i18n.locale] : "" }}
            </span>
          </div>
          <div class="text-nowrap">
            <small class="align-text-top text-capitalize">{{ data.item.event_time }}</small>
          </div>
        </template>

        <template #cell(color)="data">
          <div>
            <span
              class="align-text-top"
            >
              <feather-icon
                :icon="rowClass(data.item, 'icon')"
                :class="rowClass(data.item, 'class')"
                size="18"
              />
            </span>
          </div>
        </template>

        <!-- Column: Object  -->
        <template #cell(object_id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveMachineObjectText(data.item.object_id) }}</span>
          </div>
        </template>

        <template #cell(type)="data">
          {{ data.item.type }}
          <div class="text-nowrap">
            <small>{{ data.item.serial }}</small>
          </div>
        </template>

        <!-- Column: Company -->
        <template #cell(company_id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveMachineCompanyText(data.item.company_id) }}</span><br>
            <small><span class="align-text-top text-capitalize">{{ resolveMachineObjectText(data.item.object_id) }}</span></small>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">

          <b-badge
            v-if="data.item.status === 'inactive'"
            pill
            :variant="`light-${resolveMachineStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status === 'inactive'? 'Inactive': 'Act' }}
          </b-badge>
          <b-badge
            v-else
            pill
            :variant="`light-${ Date.now() - data.item.alive*1000 > 120000? 'danger': 'success' }`"
            class="text-capitalize"
          >
            {{ Date.now() - data.item.alive*1000 > 120000? 'Offline': 'Online' }}
          </b-badge>

        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!--b-dropdown-item :to="{ name: 'apps-machines-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item-->
              <b-dropdown-item :to="{ name: 'apps-machines-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('common.edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="duplicateMachine(data.item)">
                <feather-icon
                  icon="CopyIcon"
                />
                <span class="align-middle ml-50">{{ $t('common.duplicate') }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteMachine(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('common.delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <template #cell(dataview)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="ShoppingBagIcon"
              size="16"
              class="cursor-pointer"
              @click="$router.push({ name: 'apps-sales-list', params: { machineFilter: data.item.id }})"
            />
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="cursor-pointer mx-1"
              @click="$router.push({ name: 'apps-events-list', params: { machineFilter: data.item.id }})"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('common.showing') }} {{ dataMeta.from }} {{ $t('common.to') }} {{ dataMeta.to }} {{ $t('common.of') }} {{ dataMeta.of }} {{ $t('common.entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMachines"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  // BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import Vue from 'vue'
import store from '@/store'
import MachinesListFilters from './MachinesListFilters.vue'
import useMachinesList from './useMachinesList'
import machineStoreModule from '../machineStoreModule'
import MachineListAddNew from './MachineListAddNew.vue'

export default {
  components: {
    MachinesListFilters,
    MachineListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    // BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    rowClass(item, type) {
      let icon = ''
      let cls = ''
      if (item.description && item.description.desc) {
        let color = ''
        if (item.description && item.description.color) color = item.description.color
        if (color) {
          if (color === 'success') {
            icon = 'CheckSquareIcon'
            cls = 'text-success'
          } else if (color === 'warning') {
            icon = 'AlertTriangleIcon'
            cls = 'text-warning'
          } else if (color === 'danger') {
            icon = 'XSquareIcon'
            cls = 'text-danger'
          } else /* if (color === 'light') */ {
            icon = 'InfoIcon'
            cls = 'text-dark'
          }
        }
      }
      return type === 'icon' ? icon : cls
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-machine'

    /*
    const {
      refetchData,
    } = useMachinesList()
    */

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, machineStoreModule)
      // Vue.prototype.$webSocketsConnect({ subscription: 'machines', id: 0 }, null)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      Vue.prototype.$webSocketsDisconnect()
    })

    const isAddNewMachineSidebarActive = ref(false)

    /*

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    /*
    const companyOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    */

    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchMachines,
      fetchMachinesFile,
      tableColumns,
      perPage,
      currentPage,
      totalMachines,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMachineListTable,
      refetchData,
      // reFetchMachines,
      // UI
      resolveMachineRoleVariant,
      resolveMachineRoleIcon,
      resolveMachineStatusVariant,
      resolveMachineCompanyText,
      resolveMachineObjectText,

      // Extra Filters
      objectFilter,
      companyFilter,
      statusFilter,

      objectOptions,
      companyOptions,
      typeOptions,

      deleteMachine,
      duplicateMachine,

    } = useMachinesList()

    // Vue.prototype.$webSocketsConnect({ subscription: 'machines', id: 0 }, reFetchMachines)
    Vue.prototype.$webSocketsConnect({ subscription: 'machines', id: 0 })
    const customPopup = item => {
      // console.log(item)

      Vue.swal({
        title: 'Z-REPORT',
        // eslint-disable-next-line global-require
        html: item,
        icon: 'none',
        // imageWidth: 350,
        // imageHeight: 350,
        // imageAlt: 'Custom image',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }
    return {

      // Sidebar
      isAddNewMachineSidebarActive,
      fetchMachines,
      fetchMachinesFile,
      tableColumns,
      perPage,
      currentPage,
      totalMachines,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMachineListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveMachineRoleVariant,
      resolveMachineRoleIcon,
      resolveMachineStatusVariant,
      resolveMachineCompanyText,
      resolveMachineObjectText,

      objectOptions,
      companyOptions,
      statusOptions,
      typeOptions,

      // Extra Filters
      objectFilter,
      companyFilter,
      statusFilter,

      deleteMachine,
      duplicateMachine,

      customPopup,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style>
  table .npd_15_0 {
    padding-left: 15px;
    padding-right: 5px;
  }
  table .npd_0_15 {
    padding-left: 5px;
    padding-right: 15px;
  }
  table .npd_0_0 {
    padding-left: 5px;
    padding-right: 5px;
  }
  table .npd_0_ {
    padding-left: 5px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
