import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMachines(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/machine/machines', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMachine(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/machine/machines/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMachine(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/machine/machines/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteQR(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/machine/QR64/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicateMachine(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/machine/duplicate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMachine(ctx, machineData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/machine/machines', { machine: machineData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },
    addMachineCode(ctx, tokenData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/machine/codes', { code: tokenData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },

    fetchQR64(ctx, tokenData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/machine/QR64', { code: tokenData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },

    fetchCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchObjects() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/object/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPlanograms() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/planogram/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAttributes(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/attribute/attributes/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPLUs(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/attribute/plus/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAttribute(ctx, {
      attr, id, type, index, update, planogram,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/attribute/attributes/', {
            attr, id, type, index, update, planogram,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
